<template>
  <div @click="closeMenu">
    <el-container>
      <el-header height="50px" style="padding: 0;z-index: 999">
        <!--      <JHeader :haveRouter="false" bg-color="#353d47" hover-bg-color="#212529"/>-->
        <JHeader
          :routerList="asyncRouterMap"
          :isCollapse.sync="isCollapse"
          @routerChange="routerChange"
          haveRouter
        ></JHeader>
      </el-header>
      <div style="padding: 0;" :style="{height: $store.state.app.client.height - 50 + 'px'}">
        <sidebar
          v-if="!$store.getters.permission.roleNull"
          v-model="selectedPath"
          :defaultIsOpen="true"
          :routerList="routerList"
          @node="getNode"
          @openTabs="openTabs"
          :isCollapse.sync="isCollapse"
        ></sidebar>
        <div style="overflow: hidden;box-sizing: border-box">
          <div class="card">
<!--            <div id="lay" @contextmenu.prevent>-->
<!--              <el-tabs-->
<!--                v-model="editableTabsValue"-->
<!--                type="card"-->
<!--                closable-->
<!--                @contextmenu.prevent.native="closeRightAll"-->
<!--                @tab-click="clickTab"-->
<!--                @tab-remove="removeTab"-->
<!--              >-->
<!--                <el-tab-pane-->
<!--                  v-for="(item, index) in editableTabs"-->
<!--                  :key="item.name"-->
<!--                  :label="item.title"-->
<!--                  :name="item.name"-->
<!--                ></el-tab-pane>-->
<!--              </el-tabs>-->
<!--              <div-->
<!--                ref="closeMenu"-->
<!--                @click.stop="preventCloseClick"-->
<!--                class="close_menu"-->
<!--                :style="{top:menuTop,left:menuLeft}"-->
<!--                v-if="isShowCloseMenu"-->
<!--              >-->
<!--                <ul>-->
<!--                  <li v-if="closeRight" data-index="1">关闭右侧标签</li>-->
<!--                  <li v-if="closeLeft" data-index="2">关闭左侧标签</li>-->
<!--                  <li v-if="closeOther" data-index="3">关闭其它标签</li>-->
<!--                  <li data-index="4">关闭全部标签</li>-->
<!--                </ul>-->
<!--              </div>-->
<!--            </div>-->
            <router-view
              v-if="isRouterAlive"
              :style="{height: $store.state.app.client.height - 54 +'px'}"
              style="overflow: auto;padding: 12px;box-sizing: border-box"
            />
          </div>
        </div>
      </div>
    </el-container>
  </div>
</template>

<script>
import JHeader from "./header/index";
import sidebar from "./sidebar/index";

export default {
  name: "index",
  components: {
    JHeader,
    sidebar
  },
  provide () {//控制路由刷新
    return{
      reload: this.reload
    }
  },
  data() {
    return {
      isCollapse: false,
      selectedPath: "",
      asyncRouterMap: [],
      routerList: [],
      editableTabsValue: "1",
      editableTabs: [
        {
          title: "首页",
          name: "1",
          contentPath: "/"
        }
      ],
      tabIndex: 2,
      isShowCloseMenu: false,
      menuLeft: "0",
      menuTop: "41px",
      closeIndex: "",
      closeLeft: false,
      closeRight: false,
      closeOther: false,
      isRouterAlive: true,//控制路由刷新
    };
  },
  created() {
    // 设置菜单
    this.asyncRouterMap = this.$store.state.permission.addRouters;

  },
  mounted() {
    // this.firstName(this.routerList);//获取默认进入时的页面名称
    this.watchRouter();
  },
  watch: {
    $route(to, from) {
      // this.firstName(this.routerList);
      this.watchRouter();
    }
  },
  methods: {
    //控制路由刷新
    reload(){
      this.isRouterAlive = false
      this.$nextTick(function(){
        this.isRouterAlive = true
      })
    },
    closeRightAll(e) {
      //点击右键关闭右侧标签
      let tabs = this.editableTabs;
      tabs.forEach((item, index) => {
        if (
          item.title === e.target.innerText &&
          e.target.innerText !== "首页"
        ) {
          this.closeIndex = index;
          if (tabs.length >= 2) {
            if (tabs.length > 2) {
              this.closeOther = true;
              this.closeLeft = index >= 2;
              this.closeRight = index < tabs.length - 1;
            } else {
              this.closeLeft = false;
              this.closeRight = false;
              this.closeOther = false;
            }
            this.menuLeft = e.pageX + "px";
            this.menuTop = e.pageY + "px";
            this.isShowCloseMenu = true;
          }
        }
      });
    },
    closeMenu() {
      this.isShowCloseMenu = false;
    },
    preventCloseClick(e) {
      let tabs = this.editableTabs;
      if (e.target.dataset.index === "1") {
        //关闭右侧
        if (this.$route.path === tabs[this.closeIndex].contentPath) {
          //判断点击的标签与当前路由是否一致
          tabs.splice(this.closeIndex + 1, tabs.length);
        } else {
          tabs.splice(this.closeIndex + 1, tabs.length);
          this.$router.push({
            path: tabs[tabs.length - 1].contentPath
          });
        }
        this.isShowCloseMenu = false;
      } else if (e.target.dataset.index === "2") {
        //关闭左侧
        if (this.$route.path === tabs[this.closeIndex].contentPath) {
          tabs.splice(1, this.closeIndex - 1);
        } else {
          tabs.splice(1, this.closeIndex - 1);
          this.$router.push({
            path: tabs[1].contentPath
          });
        }
        this.isShowCloseMenu = false;
      } else if (e.target.dataset.index === "3") {
        //关闭其它
        if (this.$route.path === tabs[this.closeIndex].contentPath) {
          if (this.closeIndex === 1) {
            tabs.splice(this.closeIndex + 1, tabs.length);
          } else {
            tabs.splice(1, this.closeIndex - 1);
            tabs.splice(this.closeIndex, tabs.length);
          }
        } else {
          if (this.closeIndex === 1) {
            tabs.splice(this.closeIndex + 1, tabs.length);
          } else {
            tabs.splice(1, this.closeIndex - 1);
            tabs.splice(this.closeIndex, tabs.length);
          }
          this.$router.push({
            path: tabs[1].contentPath
          });
        }
        this.isShowCloseMenu = false;
      } else if (e.target.dataset.index === "4") {
        //关闭全部
        tabs.splice(1, tabs.length);
        this.$router.push({
          path: tabs[0].contentPath
        });
        this.isShowCloseMenu = false;
      }
    },
    watchRouter() {
      let pathInfo = {
        name: this.$route.name,
        selectedPath: this.$route.path
      };
      this.openTabs(pathInfo);
    },
    firstName(list) {
      let pathName = "";
      list.forEach((item, index) => {
        if (item.children && item.children.length > 0) {
          this.firstName(item.children);
        } else {
          if (item.selectedPath === this.$route.path) {
            pathName = item.name;
            let firstPath = {
              name: pathName,
              selectedPath: this.$route.path
            };
            this.openTabs(firstPath);
          }
        }
      });
    },
    openTabs(e) {
      //添加标签
      if (this.editableTabs.length === 0) {
        let newTabName = ++this.tabIndex + "";
        this.editableTabs.push({
          title: e.name,
          name: newTabName,
          contentPath: e.selectedPath
        });
        this.editableTabsValue = newTabName;
      } else {
        let isExitIndex = "";
        let isExit = this.editableTabs.some((item, index) => {
          if (e.name === item.title) {
            isExitIndex = item.name;
          }
          return e.name === item.title;
        });
        if (isExit) {
          this.editableTabsValue = isExitIndex;
        } else {
          let newTabName = ++this.tabIndex + "";
          this.editableTabs.push({
            title: e.name,
            name: newTabName,
            contentPath: e.selectedPath
          });
          this.editableTabsValue = newTabName;
        }
      }
    },
    clickTab(e) {
      //点击标签
      let routePath = "";
      this.editableTabs.forEach((item, index) => {
        if (item.name === e.name) {
          routePath = item.contentPath;
          return;
        }
      });
      if (routePath != "") {
        this.$router.push({
          path: routePath
        });
      }
    },
    removeTab(targetName) {
      //移除标签
      let tabs = this.editableTabs;
      let activeName = this.editableTabsValue;
      if (activeName === targetName) {
        tabs.forEach((tab, index) => {
          if (tab.name === targetName) {
            let nextTab = tabs[index + 1] || tabs[index - 1];
            if (nextTab) {
              activeName = nextTab.name;
            }
          }
        });
      }

      this.editableTabsValue = activeName;
      this.editableTabs = tabs.filter(tab => tab.name !== targetName);
      this.$router.push({
        path: this.editableTabs[this.editableTabs.length - 1].contentPath
      });
    },
    getNode(node) {
      // console.log(node);
    },
    routerChange(routerList) {
      this.routerList = routerList;
    }
  }
};
</script>

<style lang="scss">
#lay {
  .close_menu {
    width: auto;
    display: inline-block;
    height: auto;
    background: #ffffff;
    padding: 10px 0;
    border-radius: 5px;
    box-sizing: border-box;
    border: 1px solid #dcdfe6;
    position: absolute;
    z-index: 9999;

    ul {
      li {
        list-style: none;
        width: 120px;
        text-align: center;
        font-size: 14px;
        color: #606266;
        line-height: 36px;
        cursor: pointer;

        &:hover {
          background: #ecf5ff;
          color: #409eff;
        }
      }
    }
  }

  .el-tabs--card > .el-tabs__header {
    margin-bottom: 0 !important;
  }

  .el-tabs__nav .el-tabs__item:nth-child(1) span {
    display: none !important;
  }

  .el-card {
    border-bottom: none !important;
  }

  .el-card.is-always-shadow {
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
  }

  .el-tabs__content {
    display: none !important;
  }
}
</style>
